<template>
  <div id="RankList" class="view-RankList">
    <CompHeader title="排行榜" />
    <div class="changetpebox">
      <div
        class="changeitem"
        v-for="item in changetypelist"
        @click="changeclick(item.id)"
        :key="item.id"
        :class="{ accahngeitem: item.id == actypeindex }"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="center">
      <div class="topbox">
        <div class="tipsbox">
          <div class="tips" @click="gotips">
            <img src="../../assets/newImages/introduce-icon.png" alt="" />
            <p>玩法介绍</p>
          </div>
        </div>
        <div class="itemsbox">
          <div class="topitem">
            <div class="headimg">
              <img
                :src="No2?.user?.avatar ? No2?.user?.avatar : noimg"
                alt=""
              />
            </div>
            <div class="contentbox">
              <div class="userinfo">
                <div class="username">{{ No2?.user.name || "虚位以待" }}</div>
                <div class="userprice">
                  <CompCoin :num="No2.win" />
                </div>
                <div class="usertop">Top2</div>
              </div>
            </div>
          </div>
          <div class="topitem top1">
            <div class="headimg">
              <img
                :src="No1?.user?.avatar ? No1?.user?.avatar : noimg"
                alt=""
              />
            </div>
            <div class="contentbox">
              <div class="userinfo">
                <div class="username">{{ No1?.user?.name || "虚位以待" }}</div>
                <div class="userprice">
                  <CompCoin :num="No1.win" />
                </div>
                <div class="usertop1">Top1</div>
              </div>
            </div>
          </div>
          <div class="topitem">
            <div class="headimg">
              <img
                :src="No3?.user?.avatar ? No3?.user?.avatar : noimg"
                alt=""
              />
            </div>
            <div class="contentbox">
              <div class="userinfo">
                <div class="username">{{ No3?.user.name || "虚位以待" }}</div>
                <div class="userprice">
                  <CompCoin :num="No3.win" />
                </div>
                <div class="usertop">Top3</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elselist">
        <div class="elseitem" v-for="(item, i) in elselist" :key="i">
          <div class="lbox">
            <div class="numbox">
              {{ i + 4 }}
            </div>
            <div class="userimgbox">
              <img :src="item?.user?.avatar" alt="" />
            </div>
            <div class="username">{{ item?.user?.name }}</div>
          </div>

          <div class="priacebox">
            <CompCoin :num="item?.win" />
          </div>
        </div>
      </div>
    </div>
    <div class="userrankingbox">
      <div class="headimg">
        <img :src="user?.avatar" alt="" />
      </div>
      <div class="username">
        {{ user.name }}
      </div>
      <div class="topinfo">
        <div class="youtop">当前你的排名:{{ userIndex + 1 || "未上榜" }}</div>
        <div class="youtop">
          <p>距离前一名还差:</p>
          <div v-if="comparisonResult > 0">
            <CompCoin :num="comparisonResult" />
          </div>
          <div v-else>未上榜</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetRankList } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      changetypelist: [
        {
          id: 1,
          title: "今日排行",
        },
        {
          id: 2,
          title: "昨日排行",
        },
      ],
      actypeindex: 1,
      // 排行
      getRankingTopWTSList: [],
      userinfo: {},
      //其余排行
      elselist: [],
      showtype: "",
      restStatus: false,
      tipsshow: false,
      drawTime: null,
      noimg: require("../../assets/newImages/head-img-null.png"),
    };
  },
  created() {
    this.getRanking();
  },
  methods: {
    gotips() {
      this.$router.push("/Ranktips");
    },
    showtips() {
      this.tipsshow = !this.tipsshow;
    },
    changeclick(v) {
      this.actypeindex = v;
      this.getRanking();
    },
    updata() {
      this.restStatus = true;
      this.getRanking();
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    // 获取排行榜
    async getRanking() {
      let params = {
        type: this.actypeindex,
      };
      let king = await GetRankList(params);
      this.userinfo = king.data.data.user;
      this.getRankingTopWTSList = king.data.data.data;

      this.elselist = this.getRankingTopWTSList.slice(3);
    },
  },
  computed: {
    ...mapState(["user"]),
    renderList() {
      return this.getRankingTopWTSList;
    },
    No1() {
      return this.renderList[0] || { user: {} };
    },
    No2() {
      return this.renderList[1] || { user: {} };
    },
    No3() {
      return this.renderList[2] || { user: {} };
    },
    userIndex() {
      return this.getRankingTopWTSList.findIndex(
        (user) => user.user_id == this.user.id
      );
    },
    // 获取上一名
    previousUserId() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1].user_id : null;
    },
    // 获取上一名数据
    previousUserData() {
      const index = this.userIndex;
      return index > 0 ? this.getRankingTopWTSList[index - 1] : null;
    },
    // 与上一名进行对比
    comparisonResult() {
      const prevUserId = this.previousUserId;
      if (prevUserId !== null) {
        return (this.previousUserData.win - this.userinfo.win).toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-RankList {
  overflow: hidden;
  padding-top: 0.55rem;
}
.changetpebox {
  width: 2.1rem;
  height: 0.38rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #161514;
  padding: 0 0.04rem;
  .changeitem {
    width: 0.96rem;
    height: 0.3rem;
    background: url("../../assets/newImages/record-table.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #b79f79;
  }
  .accahngeitem {
    background: url("../../assets/newImages/record-actable.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
  }
}
.center {
  width: 3.35rem;
  margin: 0 auto;
  .topbox {
    width: 100%;
    height: 2rem;
    @include bgImg("ranking-topbg");
    margin-top: 0.12rem;
    position: relative;
    .tipsbox {
      width: 100%;
      height: 0.24rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .tips {
        display: flex;
        align-items: center;
        padding: 0 0.08rem;
        img {
          width: 0.14rem;
          height: 0.14rem;
          margin-right: 0.08rem;
        }
        p {
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffedd1;
        }
      }
    }
    .itemsbox {
      width: 3.1rem;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      position: absolute;
      top: 0.5rem;
      right: 0.12rem;
      .topitem {
        width: 0.94rem;
        height: 1.54rem;
        position: relative;
        .headimg {
          width: 0.66rem;
          height: 0.66rem;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            z-index: 2;
          }
        }
        .contentbox {
          width: 100%;
          height: 0.95rem;
          @include bgImg("ranking-topitem");
          position: absolute;
          top: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .userinfo {
            text-align: center;
            .username {
              font-weight: 400;
              font-size: 0.12rem;
              color: #ffffff;
              margin-bottom: 0.05rem;
            }
            .userprice {
              margin-bottom: 0.08rem;
            }
            .usertop {
              font-weight: 600;
              font-size: 0.14rem;
              color: #ffa366;
            }
          }
        }
      }
      .top1 {
        top: -0.2rem;
        .usertop1 {
          font-weight: 600;
          font-size: 0.16rem;
          color: #ffae00;
        }
      }
    }
  }
  .elselist {
    width: 100%;
    padding-bottom: 0.6rem;
    margin-top: 0.12rem;
    .elseitem {
      width: 100%;
      height: 0.58rem;
      // background: rgba(29, 19, 0, 0.6);
      @include bgImg("itemlistbg");
      border: 0.01rem solid #69552d;
      margin-bottom: 0.08rem;
      display: flex;
      align-items: center;
      padding: 0 0.18rem;
      justify-content: space-between;
      .lbox {
        display: flex;
        align-items: center;
        .numbox {
          width: 0.2rem;
          font-family: Blippo Pro, Blippo Pro;
          font-weight: normal;
          font-size: 0.24rem;
          color: #b89666;
          margin-right: 0.12rem;
        }
        .userimgbox {
          width: 0.4rem;
          height: 0.4rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .username {
          margin-left: 0.12rem;
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
        }
      }

      .priacebox {
        width: 20%;
      }
    }
  }
}
.userrankingbox {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0.56rem;
  @include bgImg("ranking-useritem");
  display: flex;
  align-items: center;
  padding: 0 0.16rem;
  .headimg {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0.12rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .username {
    width: 38%;
  }
  .topinfo {
    font-weight: 400;
    font-size: 0.12rem;
    color: #ffffff;
    .youtop {
      margin-bottom: 0.08rem;
      display: flex;
      align-items: center;
      p {
        margin-right: 0.04rem;
      }
    }
  }
}
</style>
